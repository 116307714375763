import React, { useEffect, useState } from "react";
import { getAllStudentForCounseller } from "../../../services/user/student-list/student-list";
import AddStudent from "../../../Components/Modals/Counseller/add-student";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../Contexts/auth-context";
import ReactPaginate from "react-paginate";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { IconContext } from "react-icons";
import { Dropdown } from "react-bootstrap";
import ChangePassword from "../../../Components/Modals/Counseller/change-password";

const StudentList = () => {
  const [studentList, setStudentList] = useState([]);
  const [addStudent, setAddStudent] = useState(false);
  const type = new URLSearchParams(useLocation().search).get("type");
  const [passwordChangeModal, setPasswordChangeModal] = useState(false);
  const [studentData,setStudentData] = useState()
  const [filters, setFilters] = useState({
    offset: 0,
    limit: 10,
    search: "",
    student_type: "",
  });
  const navigate = useNavigate();
  const getAllStudentList = async () => {
    await getAllStudentForCounseller(filters).then((res) => {
      if (res.data?.data) {
        setStudentList(res.data?.data);
      }
    });
  };

  useEffect(() => {
    getAllStudentList();
  }, [filters]);

  useEffect(() => {
    if (type) {
      setFilters({ ...filters, student_type: type });
    }
  }, [type]);

  return (
    <React.Fragment>
      <div
        className="flex-1 p-0"
        style={{ height: "calc(100vh - 20vh)", overflow: "scroll" }}
      >
        <div className="mt-4 overflow-hidden messages_invitations_wrap">
          <div className="row">
            <div className="col-md-12">
              <div className="h-100 p-0">
                <div className="rightPaneHeader mb-5">
                  <div className="d-flex justify-content-between w-full mb-4">
                    <h3 className="font-35 font-600 mb-0 block-title aos-init aos-animate">
                      Students
                    </h3>
                    <div className="dashboard innerpage smart-scroll">
                      <div className="d-flex mx-4 dropdown-div">
                        {/* <p className="me-2 mt-1" style={{fontWeight:500,fontSize:"20px"}}>
                        Filters :
                        </p> */}
                        <Dropdown>
                          <Dropdown.Toggle
                            className="btn jusify-content-center align-items-center w-auto height-40 d-inline-flex text-white font-14 text-decoration-none job_internship_btn aos-init aos-animate"
                            style={{
                              background: "#5cae48",
                              border: "1px solid #5cae48",
                              borderRadius: "27px",
                              padding: "0 35px",
                            }}
                            id="dropdown-basic"
                          >
                            Filters
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            style={{
                              zIndex: "unset",
                              textAlign: "center",
                              minWidth: "120px",
                            }}
                          >
                            <Dropdown.Item
                              onClick={() => {
                                setFilters({
                                  ...filters,
                                  student_type: "ug",
                                });
                              }}
                            >
                              UG
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setFilters({
                                  ...filters,
                                  student_type: "pg",
                                });
                              }}
                            >
                              PG
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <button
                        className="btn btn-green w-auto height-40 d-inline-flex text-white font-14 text-decoration-none job_internship_btn aos-init aos-animate"
                        onClick={() => setAddStudent(true)}
                      >
                        <span className="d-flex align-items-center">
                          <img
                            src="/assets/images/broadcast.svg"
                            className="me-2"
                            alt=""
                          />
                          Add Student
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="JobInternshipListing rightPaneBody">
                  <div className="polarcontsec tab-content">
                    <div className="tab-pane fade show active">
                      <div className="EventsBlk">
                        <div className="broadcast_search_module">
                          <div className="search_module">
                            <div className="broadcastSearch_wrap">
                              <form className="broadcastSearch position-relative">
                                <button
                                  type="submit"
                                  className="btn icon-search"
                                ></button>
                                <input
                                  className="form-control flex-1 font-14 font-500 w-100"
                                  type="search"
                                  value={filters.search}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      search: e.target.value,
                                    })
                                  }
                                  placeholder="Search"
                                />
                              </form>
                            </div>
                          </div>
                          <div className="broadcast_count">
                            Showing
                            <span className="current_broadcast">1-3</span>
                            of
                            <span className="total_count">3</span>
                          </div>
                        </div>
                        <div className="broadcast_table">
                          <div className="table-responsive attendee_dtl">
                            <table
                              className="table"
                              style={{ minWidth: "1020px" }}
                            >
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>DOB</th>
                                  <th>Mobile No</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {studentList?.data?.map((val, index) => {
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td>
                                          {val.fname} {val.lname}
                                        </td>
                                        <td>{val.email}</td>
                                        <td>{val.dob}</td>
                                        <td>{val.phone}</td>
                                        <td className="d-flex">
                                          <a
                                            className="btn-edit icon-edit"
                                            onClick={() =>
                                              navigate(
                                                `/user/profile/` +
                                                  val.student_id
                                              )
                                            }
                                          ></a>
                                          <button
                                            className="ms-2 btn  w-auto height-40 d-inline-flex text-white font-14 text-decoration-none"
                                            style={{
                                              background: "#5cae48",
                                              border: "1px solid #5cae48",
                                              borderRadius: "27px",
                                              padding: "0 35px",
                                            }}
                                            onClick={() =>{
                                              setPasswordChangeModal(true)
setStudentData(val?.student_id)
                                            }
                                            }
                                          >
                                            Change password
                                          </button>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </table>
                            <div className="pagination-div">
                              <ReactPaginate
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                pageClassName={"page-item"}
                                onPageChange={(event) => {
                                  setFilters({
                                    ...filters,
                                    offset: parseInt(event.selected) * 10,
                                  });
                                }}
                                breakLabel="..."
                                pageCount={Math.ceil(studentList?.total_pages)}
                                previousLabel={
                                  <IconContext.Provider
                                    value={{ color: "#B8C1CC", size: "36px" }}
                                  >
                                    <AiFillLeftCircle />
                                  </IconContext.Provider>
                                }
                                nextLabel={
                                  <IconContext.Provider
                                    value={{ color: "#B8C1CC", size: "36px" }}
                                  >
                                    <AiFillRightCircle />
                                  </IconContext.Provider>
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddStudent addStudent={addStudent} setAddStudent={setAddStudent} />
      <ChangePassword
        passwordChangeModal={passwordChangeModal}
        setPasswordChangeModal={setPasswordChangeModal}
        studentData={studentData}
      />
    </React.Fragment>
  );
};

export default StudentList;
