import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../Contexts/auth-context";
import { getStudentProducts } from "../../services/dashboard/dashboard-services";
import { getStudentManageData } from "../../services/student/student-profile";

const StudentHeader = () => {
  const { user, setUser } = useAuth();
  console.log(user);
  const [assessmentData, setAssessmentData] = useState([]);
  const [manageData, setManageData] = useState([]);

  const navigate = useNavigate();
  const companyDetail = JSON.parse(localStorage.getItem("companyDetails"));
  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    setUser(null);
    // setTimeout(() => {
      // navigate("/student-login");
    window.location.href = "/student-login";
    // }, 200);
  };
  const getAllProducts = async () => {
    const res = await getStudentProducts();
    if (res?.data?.data?.records) {
      setAssessmentData(res.data?.data?.records);
    }
  };
  const getManageAccess = async () => {
    await getStudentManageData().then((res) => {
      console.log(res);
      if (res && res.data?.data?.length) {
        setManageData(res.data?.data);
      }
    });
  };
  console.log(manageData);
  useEffect(() => {
    getAllProducts();
    getManageAccess();
  }, []);

  const takeTest = (product) => {
    if (product?.test_url) {
      navigate("/student/test-details/" + product?.user_product_id);
    } else {
      navigate("/student/test-details/" + product?.user_product_id);
    }
  };
  return (
    <React.Fragment>
      <header>
        <div className="container">
          <div className=" d-flex justify-content-between align-items-center custom-header">
            <div className="univarsity_logo manager_profile">
              <div className="logo">
                <button className="mobileonly btn-offcanvas">
                  <span className="navbar-toggler-icon">
                    <b>#</b>
                  </span>
                </button>
                <span
                  onClick={() => navigate("/student/dashboard")}
                  className="cursor-pointer"
                >
                  <img
                    src={companyDetail?.logo}
                    alt="counseller"
                    style={{
                      height: "100px",
                      width: "200px",
                      padding: "10px 0",
                      borderRadius: "10px",
                    }}
                  />
                </span>
              </div>
            </div>
            {/* <div className="search">
              <form className="form-inline d-flex flex-wrap justify-content-between">
                <button
                  className="btn btn-search icon icon-search"
                  type="submit"
                ></button>
                <input
                  className="form-control flex-1 font-16 font-500"
                  type="search"
                  placeholder="Try Searching “Top Engineering Colleges in India”"
                  aria-label="Search"
                />
              </form>
              <span className="btn-search-toggle icon-search"></span>
            </div> */}
            <div className="headerRightSide">
              <div className="mobile-search">
                <span className="btn-search-mobile-toggle icon-search"></span>
              </div>
              <ul>
                {/* <li>
                  <span className="calender">
                    <i className="icon-calendar"></i>
                  </span>
                </li> */}
                <li className="dropdown">
                  <div className="username d-flex align-items-center position-relative">
                    <label className="">
                      {user?.profile_pic ? (
                        <span className="userimg position-relative">
                          <img src={user?.profile_pic} alt="video-thumb" />
                        </span>
                      ) : (
                        <div
                          data-initials={
                            user?.fname[0]?.toUpperCase() +
                            user?.lname[0]?.toUpperCase()
                          }
                        ></div>
                      )}
                      <span className="desktoponly">
                        {user?.fname} {user?.lname}
                      </span>
                    </label>
                    <div className="user-dropdown">
                      <ul>
                        <li>
                          <span
                            onClick={() => navigate("/student/profile")}
                            className="cursor-pointer"
                          >
                            <b className="icon-main-user me-2"></b>
                            Your Profile
                          </span>
                        </li>
                        {/* <li>
                          <span
                            onClick={() => navigate("/student-reset-password")}
                          >
                            <b className="icon-lock me-2"></b>
                            Change Password
                          </span>
                        </li> */}
                        <li>
                          <span
                            onClick={() => logout()}
                            className="cursor-pointer"
                          >
                            <b className="icon-switch me-2"></b>
                            Logout
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg navbar-light custom-nav offcanvas offcanvas-start">
          <ul
            className="navbar-nav mx-auto d-flex justify-content-center"
            id="custom-nav"
          >
            <li
              className="nav-item active"
              onClick={() => navigate("/student/dashboard")}
            >
              <span className="nav-link ">
                <i className="icon icon-student"></i>
                <span>Dashboard</span>
              </span>
              {/* <ul
                className="collapse"
                id="journey-collapse"
                data-bs-parent="#custom-nav"
              >
                <li
                  onClick={() => navigate("/student/dashboard")}
                  className="cursor-pointer"
                >
                  <span>Home</span>
                </li>
              </ul> */}
            </li>
            <li className="nav-item dropdown">
              <span className="nav-link dropdown-toggle">
                <i className="icon icon-student"></i>
                <span>Explore</span>
              </span>
              <ul className="collapse">
                {manageData &&
                  manageData?.map((x) => {
                    return x.name === "Top Colleges" &&
                      x?.has_access === true ? (
                      <li
                        onClick={() =>
                          navigate("/student/college-list/?activeTab=top")
                        }
                      >
                        <span>Top Colleges</span>
                      </li>
                    ) : null;
                  })}
                {manageData &&
                  manageData?.map((x) => {
                    return x.name === "Entrance Exam" &&
                      x?.has_access === true ? (
                      <li onClick={() => navigate("/student/entrance-exam")}>
                        <span>Entrance Exams</span>
                      </li>
                    ) : null;
                  })}
                {user?.standard_alias === "school" ? (
                  <>
                    {manageData &&
                      manageData?.map((x) => {
                        return x.name === "College Compare" &&
                          x?.has_access === true ? (
                          <li
                            onClick={() =>
                              navigate(
                                "/student/college-list/?activeTab=compare"
                              )
                            }
                            className="cursor-pointer"
                          >
                            <span>College Compare</span>
                          </li>
                        ) : null;
                      })}
                  </>
                ) : (
                  ""
                )}
                {manageData &&
                  manageData?.map((x) => {
                    return x.name === "Scholarships" &&
                      x?.has_access === true ? (
                      <li
                        onClick={() => navigate("/student/scholarship-search")}
                      >
                        <span>Scholarships</span>
                      </li>
                    ) : null;
                  })}
                {manageData &&
                  manageData?.map((x) => {
                    return x?.name === "Life Skills" &&
                      x?.has_access === true ? (
                      <li onClick={() => navigate("/student/life-skills")}>
                        <span>Skills</span>
                      </li>
                    ) : null;
                  })}
              </ul>
            </li>

            <li className="nav-item dropdown">
              <span className="nav-link dropdown-toggle">
                <i className="icon icon-explore"></i>
                <span>Assessments</span>
              </span>
              <ul className="collapse">
                {assessmentData &&
                  assessmentData?.map((a, i) => {
                    return (
                      <li key={i} onClick={() => a.product_name && takeTest(a)}>
                        <span>{a.product_name ? a.product_name : "-"}</span>
                      </li>
                    );
                  })}
              </ul>
            </li>
            <li className="nav-item dropdown">
              <span className="nav-link dropdown-toggle">
                <i className="icon icon-connect"></i>
                <span>Tools</span>
              </span>
              <ul className="collapse">
                {user?.standard_alias === "school" ? (
                  <>
                    {manageData &&
                      manageData?.map((x) => {
                        return x?.name === "Fee Calculator" &&
                          x?.has_access === true ? (
                          <li
                            onClick={() => navigate("/student/course-fee")}
                            className="cursor-pointer"
                          >
                            <span>Fee Calculator</span>
                          </li>
                        ) : null;
                      })}
                  </>
                ) : (
                  ""
                )}
                {user?.standard_alias === "school" ? (
                  <>
                    {manageData &&
                      manageData?.map((x) => {
                        return x.name === "College Compare" &&
                          x?.has_access === true ? (
                          <li
                            onClick={() =>
                              navigate(
                                "/student/college-list/?activeTab=compare"
                              )
                            }
                            className="cursor-pointer"
                          >
                            <span>College Compare</span>
                          </li>
                        ) : null;
                      })}
                  </>
                ) : (
                  ""
                )}
                {manageData &&
                  manageData?.map((x) => {
                    return x?.name === "Career Trends" &&
                      x?.has_access === true ? (
                      <li onClick={() => navigate("/student/career-trends")}>
                        <span>Career Trends</span>
                      </li>
                    ) : null;
                  })}
              </ul>
            </li>
            <li className="nav-item dropdown">
              <span className="nav-link dropdown-toggle">
                <i className="icon icon-events"></i>
                <span>Meetings & Events</span>
              </span>
              {manageData &&
                manageData?.map((x) => {
                  return x?.name === "Events" && x?.has_access === true ? (
                    <>
                      <ul className="collapse">
                        <li
                          onClick={() => navigate("/student/meetings")}
                          className="cursor-pointer"
                        >
                          <span>Upcoming</span>
                        </li>
                        <li
                          onClick={() =>
                            navigate("/student/meetings/?activeTab=past")
                          }
                          className="cursor-pointer"
                        >
                          <span>Past</span>
                        </li>
                      </ul>
                    </>
                  ) : null;
                })}
            </li>
          </ul>
        </nav>
      </header>
    </React.Fragment>
  );
};

export default StudentHeader;
