import React, { useEffect, useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import CollegeSearch from "./College-Components/college-search";
import TopColleges from "./College-Components/top-colleges";
import CompareColleges from "./College-Components/compare-colleges";
import {
  getAllParentCourse,
  getAllSubCourse,
  getColleges,
  getCountries,
} from "../../services/career/career-service";
import {
  getAllCity,
  // getEntranceExam,
} from "../../services/student/student-profile";
import {
  getCollegesList,
  getCompareCollegesList,
  getState,
} from "../../services/college-list/college-list";
import { useAuth } from "../../Contexts/auth-context";
import { useLocation } from "react-router-dom";

const CollegesList = () => {
  const { user } = useAuth();
  const [activeKey, setActiveKey] = useState("college-search");
  const [collegeData, setCollegeData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [subCourseData, setSubCourseData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  // const [testData, setTestData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchCityQuery, setSearchCityQuery] = useState("");
  const [searchStateQuery, setSearchStateQuery] = useState("");
  const [searchCountryQuery, setSearchCountryQuery] = useState("");
  const [searchCourseQuery, setSearchCourseQuery] = useState("");
  const [searchSubCourseQuery, setSearchSubCourseQuery] = useState("");
  const [searchCollegeQuery, setSearchCollegeQuery] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [page, setPage] = useState(0);
  const [filterData, setFilterData] = useState({
    offset: 0,
    limit: 10,
    course_id: "",
    city_id: "",
    state_id: "",
    country_id: "",
    has_ranking: 1,
  });

  const activeTab = new URLSearchParams(useLocation().search).get("activeTab");

  const [compareCollegeData, setCompareCollegeData] = useState([
    {
      collegeId: null,
    },
    {
      collegeId: null,
    },
    {
      collegeId: null,
    },
  ]);
  const [collegeList, setCollegeList] = useState([{}, {}, {}]);
  const [colleges, setColleges] = useState([]);

  const getAllColleges = async () => {
    setLoading(true);
    setFilteredData(null);
    getCollegesList({ ...filterData, search: searchCollegeQuery }).then(
      (res) => {
        setLoading(false);

        if (res && res.data?.data) {
          setCollegeData(res.data?.data);
          let getData = res.data?.data?.data?.filter((item, index) => {
            return (
              (index >= page * filterData?.limit) &
              (index < (page + 1) * filterData?.limit)
            );
          });
          setFilteredData(getData);
        }
      }
    );
  };

  useEffect(() => {
    if (activeTab && activeTab === "compare") {
      setActiveKey("compare");
    } else if (activeTab && activeTab === "top") {
      setActiveKey("college-search");
      setFilterData({
        ...filterData,
        has_ranking: 1,
      });
    } else if (activeTab && activeTab === "college-search") {
      setActiveKey("top-college");
      setFilterData({
        ...filterData,
        has_ranking: 2,
      });
    }
  }, [activeTab]);

  const getAllCities = async () => {
    let payload = {
      search: searchCityQuery,
      state_id: filterData.state_id,
    };
    const res = await getAllCity(payload);
    if (res && res.data?.data?.records) {
      let getCity = res.data?.data?.records?.map((x) => {
        x["isChecked"] = false;
        return x;
      });
      setCityData(getCity);
    }
  };
  const getAllCourse = async () => {
    let payload = {
      search: searchCourseQuery,
    };
    await getAllParentCourse(payload).then((res) => {
      // payload
      if (res.data?.data) {
        let getCource = res.data?.data?.map((x) => {
          x["isChecked"] = false;
          return x;
        });
        setCourseData(getCource);
      }
    });
  };

  const getSubCourse = async () => {
    let payload = {
      search: searchSubCourseQuery,
      parent_id: filterData.course_id,
    };
    await getAllSubCourse(payload).then((res) => {
      if (res.data?.data) {
        let getSubCource = res.data?.data?.map((x) => {
          x["isChecked"] = false;
          return x;
        });
        setSubCourseData(getSubCource);
      }
    });
  };
  const getAllCountries = async () => {
    let payload = {
      search: searchCountryQuery,
    };
    await getCountries(payload).then((res) => {
      if (res.data?.data?.records) {
        let getCountry = res.data?.data?.records?.map((x) => {
          x["isChecked"] = false;
          return x;
        });
        setCountryData(getCountry);
      }
    });
  };
  const getAllState = async () => {
    let payload = {
      search: searchStateQuery,
      country_id: filterData.country_id,
    };
    await getState(payload).then((res) => {
      if (res.data?.data?.records) {
        let State = res.data?.data?.records?.map((x) => {
          x["isChecked"] = false;
          return x;
        });
        setStateData(State);
      }
    });
  };
  // const getAllEntranceTest = async () => {
  //   getEntranceExam().then((res) => {
  //     if (res?.data && res.data?.data?.entrance_exam) {
  //       let getExam = res.data?.data?.entrance_exam?.map((x) => {
  //         x["isChecked"] = false;
  //         return x;
  //       });
  //       setTestData(getExam);
  //     }
  //   });
  // };
  const GetAllCollegeList = async () => {
    getColleges().then((res) => {
      if (res && res?.data?.data) {
        let getColleges = res?.data?.data?.map((a) => {
          return { label: a.name, value: a.id };
        });
        setColleges(getColleges);
      }
    });
  };
  const loadOptions = async (inputValue, callback) => {
    const res = await getColleges({ search: inputValue });
    if (res?.data?.data) {
      callback(
        res.data?.data?.map((x) => {
          return { label: x.name, value: x.id };
        })
      );
    }
  };
  const getAllCompareCollege = async () => {
    let payload = compareCollegeData.map((c) => {
      return parseInt(c.collegeId);
    });
    const res = await getCompareCollegesList({ college_id: payload });
    if (res?.data?.data) {
      if (res.data?.data[0]) {
        let temp = Object.assign([], collegeList);
        temp[0] = res.data?.data[0];
        setCollegeList(temp);
      }
      if (res.data?.data[1]) {
        let temp = Object.assign([], collegeList);
        temp[1] = res.data?.data[1];
        setCollegeList(temp);
      }
      if (res.data?.data[2]) {
        let temp = Object.assign([], collegeList);
        temp[2] = res.data?.data[2];
        setCollegeList(temp);
      }
    }
  };

  useEffect(() => {
    user && GetAllCollegeList();
  }, [user]);

  useEffect(() => {
    user && getAllCities();
  }, [searchCityQuery, user, filterData.state_id]);

  useEffect(() => {
    user && getAllState();
  }, [searchStateQuery, user, filterData.country_id]);

  useEffect(() => {
    user && getAllCountries();
  }, [searchCountryQuery, user]);

  useEffect(() => {
    user && getAllCourse();
  }, [searchCourseQuery, user]);

  useEffect(() => {
    user && filterData.course_id && getSubCourse();
  }, [searchSubCourseQuery, user, filterData.course_id]);

  useEffect(() => {
    if (user) {
      getAllColleges();
    }
  }, [filterData, searchCollegeQuery, user, page]);

  useEffect(() => {
    if (compareCollegeData[0]?.collegeId) {
      getAllCompareCollege();
    }
  }, [compareCollegeData]);

  return (
    // user ?

    <div className="main w-100 d-flex flex-column">
      <div className="flex-1 container">
        <div className="mt-4">
          <div className="row">
            <div className="col-md-12">
              <div className="h-100 p-0">
                <div className="m-w-90 d-flex flex-column justify-content-between  mt-3 mb-3">
                  <h3 className="font-35 font-600 mb-0 block-title aos-init aos-animate">
                    Search for colleges
                  </h3>
                  <p className="font-18 font-500 mb-2">
                    Use the college finder tool to find the best colleges!
                    Choose from the following options.
                  </p>
                </div>

                <Card style={{ border: "none" }}>
                  <Nav
                    variant="pills"
                    className="job_internship_tab polartabsec"
                  >
                    <Nav.Item eventKey="college-search">
                      <ul className="nav nav-tabs">
                        <li className="nav-item" role="presentation">
                          <button
                            onClick={() => {
                              setActiveKey("college-search");
                              setFilterData({
                                ...filterData,
                                course_id: "",
                                city_id: "",
                                state_id: "",
                                country_id: "",
                                offset: 0,
                                has_ranking: 1,
                              });
                              setSearchCourseQuery("");
                              setSearchSubCourseQuery("");
                              setSearchCityQuery("");
                              setSearchStateQuery("");
                              setSearchCountryQuery("");
                              setSearchCollegeQuery("");
                              let temp = Object.assign([], courseData);
                              temp = temp.map((t) => {
                                t["isChecked"] = false;
                                return t;
                              });
                              setCourseData(temp);
                              let SubCourseFilter = Object.assign(
                                [],
                                subCourseData
                              );
                              SubCourseFilter = SubCourseFilter.map((t) => {
                                t["isChecked"] = false;
                                return t;
                              });
                              setSubCourseData(SubCourseFilter);
                              let CountryFilter = Object.assign(
                                [],
                                countryData
                              );
                              CountryFilter = CountryFilter.map((t) => {
                                t["isChecked"] = false;
                                return t;
                              });
                              setCountryData(CountryFilter);
                              let CityFilter = Object.assign([], cityData);
                              CityFilter = CityFilter.map((t) => {
                                t["isChecked"] = false;
                                return t;
                              });
                              setCityData(CityFilter);
                              let stateFilter = Object.assign([], stateData);
                              stateFilter = stateFilter.map((t) => {
                                t["isChecked"] = false;
                                return t;
                              });
                              setStateData(stateFilter);
                            }}
                            className={
                              activeKey === "college-search"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            id="college-search-tab"
                          >
                            Top Colleges
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className={
                              activeKey === "top-college"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            id="top-college-tab"
                            onClick={() => {
                              setActiveKey("top-college");
                              setFilterData({
                                ...filterData,
                                course_id: "",
                                city_id: "",
                                state_id: "",
                                country_id: "",
                                offset: 0,
                                has_ranking: 2,
                              });
                              setSearchCourseQuery("");
                              setSearchSubCourseQuery("");
                              setSearchCityQuery("");
                              setSearchStateQuery("");
                              setSearchCountryQuery("");
                              setSearchCollegeQuery("");
                              let temp = Object.assign([], courseData);
                              temp = temp.map((t) => {
                                t["isChecked"] = false;
                                return t;
                              });
                              setCourseData(temp);
                              let SubCourseFilter = Object.assign(
                                [],
                                subCourseData
                              );
                              SubCourseFilter = SubCourseFilter.map((t) => {
                                t["isChecked"] = false;
                                return t;
                              });
                              setSubCourseData(SubCourseFilter);
                              let CountryFilter = Object.assign(
                                [],
                                countryData
                              );
                              CountryFilter = CountryFilter.map((t) => {
                                t["isChecked"] = false;
                                return t;
                              });
                              setCountryData(CountryFilter);
                              let CityFilter = Object.assign([], cityData);
                              CityFilter = CityFilter.map((t) => {
                                t["isChecked"] = false;
                                return t;
                              });
                              setCityData(CityFilter);
                              let stateFilter = Object.assign([], stateData);
                              stateFilter = stateFilter.map((t) => {
                                t["isChecked"] = false;
                                return t;
                              });
                              setStateData(stateFilter);
                            }}
                          >
                            College Search
                          </button>
                        </li>

                        <li className="nav-item" role="presentation">
                          <button
                            className={
                              activeKey === "compare"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            id="compare-tab"
                            onClick={() => setActiveKey("compare")}
                          >
                            Compare Colleges
                          </button>
                        </li>
                      </ul>
                    </Nav.Item>
                  </Nav>
                </Card>
                <div className="row m-0 pt-4 JobInternshipListing">
                  <div className="col-lg-12">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="college-search"
                      activeKey={activeKey}
                      onSelect={(k) => setActiveKey(k)}
                    >
                      <Tab.Content>
                        <Tab.Pane eventKey="college-search">
                          <CollegeSearch
                            collegeData={collegeData}
                            courseData={courseData}
                            setCourseData={setCourseData}
                            subCourseData={subCourseData}
                            setSubCourseData={setSubCourseData}
                            countryData={countryData}
                            setCountryData={setCountryData}
                            cityData={cityData}
                            setCityData={setCityData}
                            stateData={stateData}
                            setStateData={setStateData}
                            // testData={testData}
                            // setTestData={setTestData}
                            filterData={filterData}
                            setFilterData={setFilterData}
                            loading={loading}
                            searchCityQuery={searchCityQuery}
                            setSearchCityQuery={setSearchCityQuery}
                            searchStateQuery={searchStateQuery}
                            setSearchStateQuery={setSearchStateQuery}
                            searchCountryQuery={searchCountryQuery}
                            setSearchCountryQuery={setSearchCountryQuery}
                            searchCourseQuery={searchCourseQuery}
                            setSearchCourseQuery={setSearchCourseQuery}
                            searchSubCourseQuery={searchSubCourseQuery}
                            setSearchSubCourseQuery={setSearchSubCourseQuery}
                            setPage={setPage}
                            filteredData={filteredData}
                            setSearchCollegeQuery={setSearchCollegeQuery}
                            searchCollegeQuery={searchCollegeQuery}
                            // loadOptions={loadOptions}
                          />
                        </Tab.Pane>

                        <Tab.Pane eventKey="top-college">
                          <TopColleges
                            collegeData={collegeData}
                            courseData={courseData}
                            setCourseData={setCourseData}
                            subCourseData={subCourseData}
                            loading={loading}
                            setSubCourseData={setSubCourseData}
                            countryData={countryData}
                            setCountryData={setCountryData}
                            cityData={cityData}
                            setCityData={setCityData}
                            stateData={stateData}
                            setStateData={setStateData}
                            // testData={testData}
                            // setTestData={setTestData}
                            filterData={filterData}
                            setFilterData={setFilterData}
                            searchCityQuery={searchCityQuery}
                            setSearchCityQuery={setSearchCityQuery}
                            searchStateQuery={searchStateQuery}
                            setSearchStateQuery={setSearchStateQuery}
                            searchCountryQuery={searchCountryQuery}
                            setSearchCountryQuery={setSearchCountryQuery}
                            searchCourseQuery={searchCourseQuery}
                            setSearchCourseQuery={setSearchCourseQuery}
                            searchSubCourseQuery={searchSubCourseQuery}
                            setSearchSubCourseQuery={setSearchSubCourseQuery}
                            setPage={setPage}
                            filteredData={filteredData}
                            setSearchCollegeQuery={setSearchCollegeQuery}
                            searchCollegeQuery={searchCollegeQuery}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="compare">
                          <CompareColleges
                            colleges={colleges}
                            compareCollegeData={compareCollegeData}
                            setCompareCollegeData={setCompareCollegeData}
                            collegeList={collegeList}
                            setCollegeList={setCollegeList}
                            cityData={cityData}
                            loadOptions={loadOptions}
                          />
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  //  : null;
};

export default CollegesList;
