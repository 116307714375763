import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  assignProductToStudent,
  getAllProductInventory,
  getProductById,
  getStudentForProduct,
  unAssignProductToStudent,
} from "../../../services/products/product-service";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { IconContext } from "react-icons";
import ReactPaginate from "react-paginate";

const InventoryManage = () => {
  const { id } = useParams();
  const [studentList, setStudentList] = useState([]);
  const [studentSearchQuery, setStudentSearchQuery] = useState("");
  const [productDetail, setProductDetail] = useState({});
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const [orders, setOrders] = useState({});
  const [loading, setLoading] = useState();
  const [filteredData, setFilteredData] = useState();
  const page = 0;
  const [filter, setFilter] = useState({
    offset: 0,
    limit: 10,
  });
  const getUserOrders = async () => {
    const res = await getAllProductInventory({});
    if (res?.data?.meta?.status) {
      if (res?.data?.data?.data.length) {
        let product = res?.data?.data?.data.find((x) => x.product_id == id);
        setOrders(product);
      }
    }
  };
  const getStudentsByProductId = async () => {
    let payload = {
      ...filter,
      product_id: id,
      search: studentSearchQuery,
    };
    setLoading(true);
    const res = await getStudentForProduct(payload);
    setLoading(false);
    if (res?.data?.meta?.status) {
      setStudentList(res?.data?.data?.data);
    }
    let getData = res.data?.data?.data?.filter((item, index) => {
      return (
        (index >= page * filter?.limit) & (index < (page + 1) * filter?.limit)
      );
    });
    setFilteredData(getData);
  };

  const getProductsById = async () => {
    const res = await getProductById({ id: id });
    if (res?.data?.meta?.status) {
      setProductDetail(res?.data?.data);
    }
  };

  useEffect(() => {
    if (id) {
      getProductsById();
      getStudentsByProductId();
    }
    getUserOrders();
  }, [id]);
  useEffect(() => {
    getStudentsByProductId();
  }, [studentSearchQuery]);
  useEffect(() => {
    getStudentsByProductId();
  }, [filter]);

  const assignProduct = async (student_id) => {
    const res = await assignProductToStudent({
      product_id: id,
      student_id: student_id,
    });
    if (res?.data?.meta?.status) {
      getStudentsByProductId();
      getUserOrders();
    }
  };

  const unAssignProduct = async (student_id) => {
    const res = await unAssignProductToStudent({
      user_product_id: student_id,
    });
    if (res?.data?.meta?.status) {
      getStudentsByProductId();
      getUserOrders();
    }
  };

  const addtoCart = () => {
    let cart = localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart"))
      : [];
    if (cart && cart.length > 0) {
      if (cart.findIndex((x) => x.id === id) > -1) {
        cart[cart.findIndex((x) => x.id === id)].quantity = quantity;
      } else {
        cart.push({
          product: productDetail,
          quantity: quantity,
          total:
            parseInt(productDetail?.price) -
            (productDetail.discount_type === "flat"
              ? productDetail.discount
              : (productDetail.discount / 100) * productDetail.price) *
              quantity,
          tax: productDetail.tax_percentage
            ? (parseInt(productDetail?.price) -
                (productDetail.discount_type === "flat"
                  ? productDetail.discount
                  : (productDetail.discount / 100) * productDetail.price) *
                  productDetail.tax_percentage) /
              100
            : 0,
        });
      }
      localStorage.setItem("cart", JSON.stringify(cart));
    } else {
      cart = [];
      cart.push({
        product: productDetail,
        quantity: quantity,
        total:
          parseInt(productDetail?.price) -
          (productDetail.discount_type === "flat"
            ? productDetail.discount
            : (productDetail.discount / 100) * productDetail.price) *
            quantity,
        tax: productDetail.tax_percentage
          ? (parseInt(productDetail?.price) -
              (productDetail.discount_type === "flat"
                ? productDetail.discount
                : (productDetail.discount / 100) * productDetail.price) *
                productDetail.tax_percentage) /
            100
          : 0,
      });
      localStorage.setItem("cart", JSON.stringify(cart));
    }
    navigate("/user/product-checkout/" + id);
  };

  return (
    <React.Fragment>
      <div className="flex-1">
        <div className="pt-4 mt-2">
          <div className="m-w-90 d-flex flex-wrap mb-4">
            <div className="headerTitle w-100">
              <h3 className="font-32 font-600 mb-0 block-title">
                {productDetail?.name}
                <i className="icon-right-arrow font-18"></i>
                <span className="text-green">Manage Inventory</span>
              </h3>
            </div>
          </div>

          <div className="row g-4">
            <div className="col-md-6">
              <div className="custom_card">
                <div className="custom_card_header">
                  <h2>
                    <span>Consumption Funnel</span>
                  </h2>
                  {/* <div className="custom_date">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Date Range"
                    />
                  </div> */}
                </div>
                <div className="custom_card_body">
                  <div className="d-flex flex-wrap justify-content-center">
                    <div className="triangleGraph">
                      <ul>
                        <li className="d-flex justify-content-center">
                          <span>{orders.total_purchase}</span>
                        </li>
                        <li className="d-flex justify-content-center">
                          <span>{orders.assigned_count}</span>
                        </li>
                        <li className="d-flex justify-content-center">
                          <span>{orders.consumed_count}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="triangleGraphLabel">
                      <ul>
                        <li>
                          <span>Units Purchased</span>
                        </li>
                        <li>
                          <span>Units Allocated</span>
                        </li>
                        <li>
                          <span>Units Consumed</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="add_unit_section_wrap">
                <div className="add_unit_section">
                  <p>Units to be added</p>
                  <div className="add_qty">
                    <a
                      className="add_count"
                      onClick={() =>
                        setQuantity(quantity != 0 ? quantity - 1 : quantity)
                      }
                    ></a>
                    <input
                      className="quantity_number"
                      value={quantity}
                      readOnly
                      type="text"
                    />
                    <a
                      className="del_count"
                      onClick={() => setQuantity(quantity + 1)}
                    ></a>
                  </div>
                </div>
                <div className="add_units_values">
                  <div className="add_units_values_row">
                    <div className="label">Total Price:</div>
                    <div className="labelprice">
                      <b>₹</b>
                      <span>{(productDetail.price * quantity).toFixed(2)}</span>
                    </div>
                  </div>
                  <div className="add_units_values_row">
                    <div className="label">Offer Price:</div>
                    <div className="labelprice">
                      <b>₹</b>
                      <span>
                        {(
                          (parseInt(productDetail?.price) -
                            (productDetail.discount_type === "flat"
                              ? productDetail.discount
                              : (productDetail.discount / 100) *
                                productDetail.price)) *
                          quantity
                        ).toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <div className="add_units_values_row">
                    <div className="label">Taxes:</div>
                    <div className="labelprice">
                      <b>₹</b>
                      <span>
                        {" "}
                        {productDetail.tax_percentage
                          ? (
                              ((parseInt(productDetail?.price) -
                                (productDetail.discount_type === "flat"
                                  ? productDetail.discount
                                  : (productDetail.discount / 100) *
                                    productDetail.price) *
                                  productDetail.tax_percentage) /
                                100) *
                              quantity
                            ).toFixed(2)
                          : 0}
                      </span>
                    </div>
                  </div>
                  <div className="add_units_values_row">
                    <div className="label">Final Price:</div>
                    <div className="labelprice">
                      <b>₹</b>
                      <span>
                        {" "}
                        {(
                          (parseInt(productDetail?.price) -
                            parseInt(
                              productDetail.discount_type === "flat"
                                ? productDetail.discount
                                : (productDetail.discount / 100) *
                                    productDetail.price
                            ) +
                            (productDetail.tax_percentage
                              ? (parseInt(productDetail?.price) -
                                  (productDetail.discount_type === "flat"
                                    ? productDetail.discount
                                    : (productDetail.discount / 100) *
                                      productDetail.price) *
                                    productDetail.tax_percentage) /
                                100
                              : 0)) *
                          quantity
                        ).toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <button onClick={() => addtoCart()} className="btn-addToCart">
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row m-0 pt-4 pb-5 JobInternshipListing">
            <div
              className="flex-1 polarcontsec tab-content p-0"
              id="myTabContent"
              data-aos="fade-left"
              data-aos-delay="1200"
            >
              <div
                className="tab-pane fade show active"
                id="applications"
                role="tabpanel"
                aria-labelledby="applications-tab"
              >
                <div className="ActiveListings_present">
                  <div className="d-flex flex-wrap justify-content-between align-items-start align-items-md-center mb-2 filterSearch">
                    <div className="d-flex flex-wrap flex-1 mb-0 justify-content-start">
                      <button
                        className="btn-grpfiter me-3"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <i className="icon-filter font-13 me-2"></i>
                        Assignment Status
                      </button>
                      <div className="search alumniSearch m-0 open position-static flex-1">
                        <form className="form-inline d-flex flex-wrap justify-content-between d-block">
                          <input
                            type="search"
                            placeholder="Search by student name, email, mobile"
                            aria-label="Search"
                            value={studentSearchQuery}
                            onChange={(e) =>
                              setStudentSearchQuery(e.target.value)
                            }
                            className="form-control flex-1 font-13 font-500 text-truncate"
                          />
                          <button
                            className="btn btn-search icon icon-search"
                            type="submit"
                          ></button>
                        </form>
                        <span className="btn-search-toggle icon-search"></span>
                      </div>
                    </div>
                  </div>

                  {/* <div className="fitergroup mb-3">
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                    >
                      <div className="accordion-body p-4">
                        <form className="row g-3">
                          <div className="col-md-4">
                            <select
                              className="form-select"
                              name="complete Class 12"
                              aria-label="Default select example"
                            >
                              <option value="">Aspirant Type</option>
                            </select>
                          </div>
                          <div className="col-md-3">
                            <select
                              className="form-select"
                              name="complete Class 12"
                              aria-label="Default select example"
                            >
                              <option value="" selected="">
                                Assignment Status
                              </option>
                              <option value="" selected="selected">
                                School
                              </option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                            </select>
                          </div>
                          <div className="col-md-5">
                            <div className="datewrap">
                              <label
                                id="config-demo"
                                className="daterangePickerList"
                              ></label>
                              <div className="from">
                                <input
                                  type="text"
                                  data-name="from"
                                  placeholder="Account Creation From"
                                  className="form-control"
                                />
                              </div>
                              <div className="arrow">
                                <span className="icon-right"></span>
                              </div>
                              <div className="to">
                                <input
                                  type="text"
                                  data-name="to"
                                  placeholder="Account Creation To"
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <select
                              className="form-select"
                              name="complete Class 12"
                              aria-label="Default select example"
                            >
                              <option value="" selected="selected">
                                Consumption Count
                              </option>
                              <option value="Completed">Completed</option>
                              <option value="Pending">Pending</option>
                              <option value="Due">Due</option>
                            </select>
                          </div>
                          <div className="col-md-5">
                            <div className="datewrap">
                              <label
                                id="config-demo2"
                                className="daterangePickerList"
                              ></label>
                              <div className="from">
                                <input
                                  type="text"
                                  data-name="from"
                                  placeholder="Account Creation From"
                                  className="form-control"
                                />
                              </div>
                              <div className="arrow">
                                <span className="icon-right"></span>
                              </div>
                              <div className="to">
                                <input
                                  type="text"
                                  data-name="to"
                                  placeholder="Account Creation To"
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div> */}

                  <div className="d-flex flex-wrap align-items-center mb-3 w-100 justify-content-between mailerLinks-parent">
                    <div className="d-flex flex-wrap align-items-center flex-1 mailerLinks">
                      {/* <div className="d-flex justify-content-start align-items-center customselect_parnt">
                        <select
                          name="complete Class 12"
                          aria-label="Default select example"
                          className="form-select w-120"
                        >
                          <option value="Action">Bulk Action</option>
                          <option value="Mark as Unseen">Mark as Unseen</option>
                          <option value="Mark as Seen">Mark as Seen</option>
                          <option value="Mark Important">Mark Important</option>
                          <option value="Unmark Important">
                            Unmark Important
                          </option>
                          <option value="Delete">Delete</option>
                          <option value="Ignore">Ignore</option>
                          <option value="Block">Block</option>
                        </select>
                        <button className="btn btn-darkblue" type="submit">
                          <span className="line-height-16">Apply</span>
                        </button>
                      </div> */}
                    </div>
                    <div className="d-flex flex-wrap align-items-center justify-content-end">
                      {/* <div className="d-flex justify-content-start align-items-center customselect_parnt me-auto mobile-100">
                        <select
                          name="complete Class 12"
                          aria-label="Default select example"
                          className="form-select w-120"
                        >
                          <option value="Action">Show 20</option>
                          <option value="Mark as Unseen">Mark as Unseen</option>
                          <option value="Mark as Seen">Mark as Seen</option>
                          <option value="Mark Important">Mark Important</option>
                          <option value="Unmark Important">
                            Unmark Important
                          </option>
                          <option value="Delete">Delete</option>
                          <option value="Ignore">Ignore</option>
                          <option value="Block">Block</option>
                        </select>
                      </div> */}
                      {/* <h3 className="font-14 font-500 mb-0 me-2">
                        Showing 1-20 of 220 records
                      </h3> */}
                      {/* <div className="d-flex flex-wrap custom-btn">
                        <span className="btn-prev icon-left-arrow"></span>
                        <span className="btn-prev icon-right-arrow"></span>
                      </div> */}
                    </div>
                  </div>

                  <div className="table-responsive attendee_dtl">
                    <table className="table" style={{ minWidth: "1010px" }}>
                      <thead>
                        <tr>
                          <th>
                            <div className="d-flex justify-content-center flex-wrap">
                              {/* <input
                                type="checkbox"
                                value=""
                                id="Antony"
                                required=""
                                className="form-check-input me-2 mt-0"
                              /> */}
                              Name &amp; Type
                              <i className="icon-sort-arrow d-flex flex-column ms-2">
                                <i className="icon-invert-down-arrow"></i>
                                <i className="icon-down-arrow-invert"></i>
                              </i>
                            </div>
                          </th>
                          <th className="text-center">Email</th>
                          <th className="text-center">Assigned Date</th>
                          <th className="text-center">Consumption Date</th>
                          <th className="text-center" width="300">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData?.map((student) => (
                          <tr>
                            <td>
                              <div className="d-flex align-items-center justify-content-center checkoption">
                                {/* <input
                                  className="form-check-input me-2"
                                  type="checkbox"
                                  value=""
                                  id="Antony"
                                  required=""
                                /> */}
                                <a className="tablink" href="javascript:;">
                                  <i className="me-2">
                                    {student?.profile_pic ? (
                                      <img
                                        src={student?.profile_pic}
                                        className="img-fluid rounded-circle"
                                        width="30"
                                        height="auto"
                                      />
                                    ) : (
                                      <span
                                        className=" rounded-circle"
                                        style={{
                                          width: "30px",
                                          fontSize: "13px",
                                          height: "30px",
                                          background: "#0c3453",
                                          display: "flex",
                                          alignItems: "center",
                                          color: "#fff",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {student?.fname[0]?.toUpperCase() +
                                          student?.lname[0]?.toUpperCase()}
                                      </span>
                                    )}
                                  </i>
                                  <span
                                    data-bs-toggle="tooltip"
                                    title="Ravish Gautam"
                                    className="singlelinename font-16 lineHeight-16"
                                  >
                                    {student.fname} {student.lname},
                                    {/* <span className="d-block font-13 fst-italic">
                                      Class 9
                                    </span>  */}
                                  </span>
                                </a>
                              </div>
                            </td>
                            <td className="text-center">{student?.email}</td>
                            <td className="text-center">
                              {student?.assigned_at
                                ? new Date(
                                    student.assigned_at
                                  ).toLocaleDateString("en-GB", {
                                    day: "numeric",
                                    month: "numeric",
                                    year: "numeric",
                                  })
                                : "-"}
                            </td>
                            <td className="text-center">
                            {student?.started_at
                                ? new Date(
                                    student.started_at
                                  ).toLocaleDateString("en-GB", {
                                    day: "numeric",
                                    month: "numeric",
                                    year: "numeric",
                                  })
                                : "-"}
                            </td>
                            <td className="text-center d-flex justify-content-center">
                              {student?.assigned_at && student.started_at ? (
                                <div className="d-flex flex-wrap cursor-pointer justify-content-end">
                                  <a
                                    className="btn-viewReport cursor-pointer"
                                    onClick={() =>
                                      window.open(student.report_url)
                                    }
                                  >
                                    View Report
                                  </a>
                                </div>
                              ) : null}
                              {student?.assigned_at && !student.started_at ? (
                                <div className="d-flex cursor-pointer flex-wrap justify-content-end">
                                  <a
                                    className="btn-unassign cursor-pointer"
                                    onClick={() => {
                                      unAssignProduct(student.user_product_id);
                                    }}
                                  >
                                    Unassign
                                  </a>
                                </div>
                              ) : null}
                              {!student?.assigned_at && !student.started_at ? (
                                <div className="d-flex flex-wrap justify-content-end">
                                  <a
                                    className="btn-assign cursor-pointer"
                                    style={{
                                      fontSize: "14px",
                                      padding: "9px 10px",
                                      marginRight: "0",
                                    }}
                                    onClick={() => {
                                      assignProduct(student.student_id);
                                    }}
                                  >
                                    Assign
                                  </a>
                                </div>
                              ) : null}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <a className="load_more_btn">
          <img src="/assets/images/load_more_icon.jpg" />
          Load More
        </a>
      )}
      {!studentList?.length ? (
        ""
      ) : (
        <div className="pagination-div">
          <ReactPaginate
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageClassName={"page-item"}
            onPageChange={(event) => {
              setFilter({
                ...filter,
                offset: parseInt(event.selected) * 10,
              });
            }}
            breakLabel="..."
            pageCount={Math.ceil(
              studentList.total_pages && studentList.total_pages
            )}
            previousLabel={
              <IconContext.Provider value={{ color: "#B8C1CC", size: "36px" }}>
                <AiFillLeftCircle />
              </IconContext.Provider>
            }
            nextLabel={
              <IconContext.Provider value={{ color: "#B8C1CC", size: "36px" }}>
                <AiFillRightCircle />
              </IconContext.Provider>
            }
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default InventoryManage;
